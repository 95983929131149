<template>
  <div>
    <div class="card card-flush  h-md-50 mb-5 mb-xl-10" style="display: flex;justify-content: space-around;min-height: 20vh">
      <div class="card-header pt-3">
        <div class="card-title flex-column">
          <div class="d-flex align-items-center">
            <span class="fw-bold text-gray-900 me-2 lh-1 ls-n2">快捷入口</span>
          </div>
        </div>

      </div>

      <div style="display: flex;justify-content: center;">
        <el-row style="width: 100%">
          <el-col :span="8">
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
              <img src="https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E7%BB%846%402x.png"
                   width="50px">
              <div class="dayPlayTextTop">
                新建商品
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center">
              <img
                  src="https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E7%9F%A2%E9%87%8F%E6%99%BA%E8%83%BD%E5%AF%B9%E8%B1%A1%402x%20(1).png"
                  width="50px">
              <div class="dayPlayTextTop">
                发票管理
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center">
              <img
                  src="https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E7%9F%A2%E9%87%8F%E6%99%BA%E8%83%BD%E5%AF%B9%E8%B1%A1%402x%20(2).png"
                  width="50px">
              <div class="dayPlayTextTop">
                经营分析
              </div>
            </div>
          </el-col>

          <el-col :span="8">
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;margin-top: 20px">
              <img
                  src="https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E7%9F%A2%E9%87%8F%E6%99%BA%E8%83%BD%E5%AF%B9%E8%B1%A1%402x%20(3).png"
                  width="50px">
              <div class="dayPlayTextTop">
                子账号管理
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;margin-top: 20px">
              <img
                  src="https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E7%9F%A2%E9%87%8F%E6%99%BA%E8%83%BD%E5%AF%B9%E8%B1%A1%402x.png"
                  width="50px">
              <div class="dayPlayTextTop">
                每日收益
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;margin-top: 20px;margin-bottom: 10px">
              <img
                  src="https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E7%9F%A2%E9%87%8F%E6%99%BA%E8%83%BD%E5%AF%B9%E8%B1%A1%402x%20(4).png"
                  width="50px">
              <div class="dayPlayTextTop">
                直营店评价
              </div>
            </div>
          </el-col>
        </el-row>


      </div>

    </div>

  </div>

</template>
<script>
import echarts from "echarts";
import {getTotalSalesRevenueDayApi} from "@/api/orderApi";

export default {
  name: 'dayPrice',
  data() {
    return {
      data: [],
      ratio: 0,
      totalSalesRevenue: 0,
      chartOptions: {
        grid: {
          left: '100px',  // 调整图表容器左侧边距
          containLabel: true,
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: -10,
          top: 20,
          data: ['京东货源', '厂供货源', '自有货源'],
          // formatter: function(name) {
          //   // 在图例后面显示对应的数值
          //   var value = echarts.getOption().series[0].data.find(item => item.name === name).value;
          //   return name + ' : ' + value;
          // },
        },
        series: [
          {
            name: '总销售额',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 8,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {value: 0, name: '京东货源'},
              {value: 0, name: '厂供货源'},
              {value: 0, name: '自有货源'},
            ]
          }
        ]
      }
    }
  },
  methods: {
    async initChart() {
      // await this.getTotalSalesRevenueDay();
      // 获取图表容器
      let chartContainer = this.$refs.chartContainer;
      // 初始化图表
      let myChart = echarts.init(chartContainer);

      // 使用 setOption 方法设置图表配置和数据
      myChart.setOption(this.chartOptions);
    },
    async getTotalSalesRevenueDay() {
      let {data} = await getTotalSalesRevenueDayApi()
      this.chartOptions.series[0].data[0].value = data.jdTotalSalesRevenue;
      this.chartOptions.series[0].data[1].value = data.sjTotalSalesRevenue;
      this.chartOptions.series[0].data[2].value = data.oneselfTotalSalesRevenue;
      this.totalSalesRevenue = data.totalSalesRevenue;
      let tempRatio = data.ratio * 100;
      if (data.ratio < 0) {
        tempRatio = tempRatio * -1;
      }
      this.ratio = tempRatio;
    }
  },
  mounted() {
    this.initChart();
  }
}
</script>

<style scoped>
.dayPlayTextTop{
  margin-top: 10px;
}
</style>