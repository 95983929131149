import {addComboListApi, queryNoSelectionComboPageApi, queryNoSelectionGoodsPageApi} from "@/api/lxh/selection";
import {getSelectionCategoryListApi, getStoreListApi,getSelectionJdCategoryListApi} from "@/api/lxh/store";
import GoodInfoComponent from "@/components/GoodInfoComponent/index.vue";

export const mixins = {
    components:{
        GoodInfoComponent
    },
    data(){
        return{

            propsCategory:{
               lazy: true,
               lazyLoad(node,resolve) {
                   const {level}  = node;
                   setTimeout(async () => {
                       let parentId = node.value || null
                       let { data} = await getSelectionCategoryListApi({parentId})
                       const nodes = data
                           .map(item => ({
                               value: item.id,
                               label: item.categoryName,
                               leaf: level >= 3 || item.categoryName==='全部'
                           }));
                       // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                       resolve(nodes);
                   }, 50);
               }
            },
            propsJdCategory:{
                lazy: true,
                lazyLoad(node,resolve) {
                    const {level}  = node;
                    setTimeout(async () => {
                        let parentId = node.value || null
                        let { data} = await getSelectionJdCategoryListApi({parentId})
                        const nodes = data
                            .map(item => ({
                                value: item.id,
                                label: item.categoryName,
                                leaf: level >= 2 || item.categoryName==='全部'
                            }));
                        // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                        resolve(nodes);
                    }, 50);
                }
            },
            chooseStoreDialog:false,
            goodsDetailVisible:false,
            params:{
                current:1,
                size:10,
                flag:1,
                categoryId1:'',
                categoryId2:'',
                categoryId3:'',
                categoryId4:'',
                goodsPlace:1,
                isJd:1,
                title:'',
                maxSalePrice:'',
                minSalePrice:''
            },
            rows:[],
            storeList:[],
            chooseStoreId:"",
            chooseComboIdList:[],
            categoryId2List:[],
            categoryId3List:[],
            categoryId4List:[],
            selectedCategory:[],
            optionsCategetoryCascader: [],
            optionsJdCategetoryCascader:[],
        }
    },

    methods:{
        changeSelectedGoodsFrom(goodsPlace,isJd){
            if(goodsPlace===2 && this.optionsJdCategetoryCascader.length<1){
                this.getJdSelectionCategory1List();
            }
          this.params.goodsPlace = goodsPlace
          this.params.isJd = isJd
          this.search()
        },
        changeCascader(e) {
            if(!e || e.length===0){
                this.params.categoryId1=''
                this.params.categoryId2=''
                this.params.categoryId3=''
                this.params.categoryId4=''
                return
            }
            e.forEach((item, index) => {
                this.params[`categoryId${index + 1}`] = item
            })
        },

        async getList(){
            console.log("aaa")
            let res;
            if(this.params.goodsPlace===1){
               res = await queryNoSelectionComboPageApi(this.params)
            }else{
               res = await queryNoSelectionGoodsPageApi(this.params)
            }
            this.rows = res.data.rows
            this.total = res.data.total
        },
        /**
         * 获取门店列表
         */
        async getStoreList(id) {
            let {data} = await getStoreListApi()
            this.storeList = data.rows;
            if (data.rows.length) {
                if (id) {
                    this.chooseStoreId = id
                    this.storeChange(id)
                } else {
                    this.chooseStoreId = data.rows[0].id
                    this.storeChange(this.chooseStoreId)
                }
            }

        },
        storeChange(e) {
            this.storeInfo = JSON.parse(JSON.stringify(this.storeList.find(item => item.id === e)))
            if (this.storeInfo.storeLogo.indexOf("https://") !== 0) {
                this.storeInfo.storeLogo = 'https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com' + this.storeInfo.storeLogo
            }
        },

        search(){
          this.params.current=1
          this.getList()
        },
        resetSearch(){
            let goodsPlace = this.params.goodsPlace
            let isJd = this.params.isJd
            this.params = {
                current:1,
                size:10,
                flag:1,
                categoryId2:'',
                categoryId3:'',
                categoryId4:'',
                title:'',
                maxSalePrice:'',
                minSalePrice:'',
                goodsPlace:goodsPlace,
                isJd:isJd
            }
            this.categoryId3List=[]
            this.categoryId4List=[]
            this.getList()
        },
        selectOrderPlacement(item){
            this.chooseStoreDialog = true
            this.chooseComboIdList.push(item.id)

        },
        async submitChoose(){
            if(this.chooseStoreId===''){
                this.$message.error("请选择门店")
                return
            }
            let type;
            if(this.params.goodsPlace===1){
                type = 3
            }else{
                if(this.params.isJd===1){
                    type = 1
                }else if(this.params.isJd===2){
                    type = 2
                }
            }
            let param = {
                comboIdList:this.chooseComboIdList,
                storeId:this.chooseStoreId,
                type
            }
            let res  = await addComboListApi(param)
            if(res.code === 200){
                this.$message.success("添加成功")
                this.chooseComboIdList = []
                this.chooseStoreDialog = false
                await this.getList()
            }
        },
        async getSelectionCategory1List(){
            let {data}  = await getSelectionCategoryListApi({parentId:0})
            this.optionsCategetoryCascader =  data
                .map(item => ({
                    value: item.id,
                    label: item.categoryName,
                    leaf: false
                }));
        },
        async getJdSelectionCategory1List(){
            let {data}  = await getSelectionJdCategoryListApi({parentId:0})
            this.optionsJdCategetoryCascader =  data
                .map(item => ({
                    value: item.id,
                    label: item.categoryName,
                    leaf: false
                }));
        },
        openGoodInfo(id) {
            if(this.params.goodsPlace===1){
                return;
            }
            this.goodsDetailVisible = true
            //this.$refs.goodInfo.open()
            setTimeout(()=>{
                this.$refs.goodInfoComponent.openGoodsInfo(id, this.params.isJd);
            },200)
        },
        openSelected(){
            this.$router.push({name: 'SelectionPending'})
        }
    },
    created() {
        this.getList()
        this.getStoreList()
        this.getSelectionCategory1List();

    }
}
