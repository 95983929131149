<template>

  <div id="kt_post" class="post flex-column-fluid">
    <TitleCard title="选品库管理" class="mt-5 mb-5" style="margin: 0 auto; max-width: 97%; border-radius: 15px">
      <template v-slot:subTitle class="position-relative">
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted" rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
              style="font-family: 'Roboto', sans-serif">
            Digital Direct Store Management
          </li>
        </ul>
      </template>
      <div style="margin-right: 35px">
        <el-button type="primary" size="mini" @click="openSelected">打开已选</el-button>
      </div>
    </TitleCard>
    <div id="kt_content_container" class="container"
         style="max-width: 98%; flex-direction: row !important; display: flex">
      <div id="kt_app_main" class="app-main flex-column flex-row-fluid">
        <div class="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" class="app-content" style="padding-top: 0px">
            <div style="min-height: 80vh">


              <el-row :gutter="20" style="margin-top: 10px">
                <el-col :span="5">
                  <div class="card card-flush py-4">
                    <div class="card-body pt-0" style="margin-top: 20px">
                      <div class="mb-5"  style="position: relative">
                        <div class="nav-group nav-group-fluid">
                          <label>
                            <input type="radio" value="1" class="btn-check" :checked="params.goodsPlace === 1" @change="changeSelectedGoodsFrom(1,2)">
                            <span class="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                    到店服务
                  </span>
                          </label>
                          <label>
                            <input type="radio" value="2" class="btn-check" :checked="params.goodsPlace === 2" @change="changeSelectedGoodsFrom(2,2)">
                            <span class="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                    优选商品
                  </span>
                          </label>
                        </div>
                        <div v-if="params.goodsPlace === 2" class="arrow-down" style="position: absolute;bottom: -20px;right: 23%;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#009ef7"
                               class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path
                                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg>
                        </div>
                      </div>
                      <div class="mb-5" v-if="params.goodsPlace === 2">
                        <div class="nav-group nav-group-fluid">
                          <label >
                            <input type="radio" value="2" class="btn-check" :checked="params.isJd === 2" @change="changeSelectedGoodsFrom(2,2)">
                            <span class="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                    自营商品
                  </span>
                          </label>
                          <label>
                            <input type="radio" value="1" class="btn-check" :checked="params.isJd === 1" @change="changeSelectedGoodsFrom(2,1)">
                            <span class="btn btn-sm btn-color-muted btn-active btn-active-primary fw-bolder px-4">
                    京东商品
                  </span>
                          </label>
                        </div>
                      </div>
                      <div class="separator separator-dashed my-8"></div>
                      <div class="mb-5">
                        <label class="fs-6 form-label fw-bolder text-dark">商品标题</label>
                        <div class="position-relative">
                    <span
                        class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
                    >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                      >
                        <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                          ></path>
                          <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                          ></path>
                        </g>
                      </svg>
                    </span>
                          <input
                              type="text"
                              v-model="params.title"
                              class="form-control form-control-solid ps-10"
                              placeholder="请输入商品标题名称"
                          />
                        </div>
                      </div>
                      <div class="mb-5">
                        <label class="fs-6 form-label fw-bolder text-dark">分类</label>
                        <div class="row" v-if="params.goodsPlace===1">
                          <el-cascader
                              clearable
                              v-model="selectedCategory"
                              :props="propsCategory"
                              :lazy="true"
                              :options="optionsCategetoryCascader"
                              placeholder="请选择"
                              @change="changeCascader"
                              id="cascader"
                              style="width: 100%;background: #F9F9F9"
                          ></el-cascader>
                        </div>

                        <div class="row" v-else>
                          <el-cascader
                              clearable
                              v-model="selectedCategory"
                              :props="propsJdCategory"
                              :lazy="true"
                              :options="optionsJdCategetoryCascader"
                              placeholder="请选择"
                              @change="changeCascader"
                              id="cascader"
                              style="width: 100%;background: #F9F9F9"
                          ></el-cascader>
                        </div>
                      </div>


                      <div class="mb-5"><label class="fs-6 form-label fw-bolder text-dark">价格区间</label>
                        <div class="row">
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input type="text" id="minSupplyPrice"
                                   name="minSupplyPrice"
                                   oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                   placeholder="最小值" v-model="params.minSalePrice"
                                   class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                            <div class="fv-plugins-message-container invalid-feedback"></div>
                          </div>
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input type="text" id="maxSupplyPrice"
                                   name="maxSupplyPrice"
                                   oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                   placeholder="最大值" v-model="params.maxSalePrice"
                                   class="form-control form-control-lg form-control-solid">
                            <div class="fv-plugins-message-container invalid-feedback"></div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-end">
                        <a
                            href="javascript:void(0);"
                            class="btn text-hover-primary bg-hover-light  btn-color-gray-400 me-3"
                            @click="resetSearch"
                        >重置</a>&nbsp;&nbsp;&nbsp;
                        <a
                            href="javascript:void(0);"
                            class="btn btn-primary"
                            @click="search"
                        >筛选</a>
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="19">
                  <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="border-radius: 15px">
                    <div class="tab-content">
                      <div
                          class="tab-pane fade show active">
                        <div class="d-flex flex-column gap-7 gap-lg-10">
                          <div class="card card-flush"
                               style="border-radius:15px !important; ">
                            <div class="card-body pt-0">
                              <div class="row" style="padding-left: 0;padding-right: 0;padding-bottom: 0">
                                <div class="table-responsive" style="display:flex;">
                                  <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
                                    <thead>
                                    <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                      <th rowspan="1" colspan="1"
                                          style="width: 312.141px;margin-left: 20px">
                                        商品信息
                                      </th>

                                      <th tabindex="0" rowspan="1" colspan="1" class="text-center" style="width:100px;">
                                        分类
                                      </th>
                                      <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                                        价格
                                      </th>
                                      <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                                        服务费
                                      </th>
                                      <th rowspan="1" colspan="1" class="text-center sorting_disabled" style="width:100px;">
                                        操作
                                      </th>
                                    </tr>
                                    </thead>
                                    <tbody class="fw-semibold text-gray-600">
                                    <tr class="old" v-for="(item,index) in rows" :key="index">
                                      <td>
                                        <div class="d-flex align-items-center" style="margin-left: 20px" @click="openGoodInfo(item.id)">
                                          <a href="javascript:void(0);" class="symbol symbol-50px">
                                            <img
                                                :src="item.imgUrl"
                                                class="symbol-label lozad">
                                          </a>
                                          <div class="ms-5">
                                            <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5">
                                              {{item.title}}
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="text-center pe-0">
                              <span>
                                {{item.categoryId2Name}}
                                <br/>
                                {{item.categoryId3Name}}
                              </span>
                                      </td>
                                      <td class="text-center pe-0">
                                        {{ item.salePrice|format }}
                                      </td>
                                      <td class="text-center pe-0">
                                        {{ item.right|format }}
                                      </td>

                                      <td class="text-center">
                                        <el-link type="primary" @click="selectOrderPlacement(item)">挂单</el-link>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div style="display: flex;justify-content: center">
                                  <paginations :total="total" :page.sync="params.current" :limit.sync="params.size"
                                               @pagination="getList"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </el-col>
              </el-row>

            </div>

          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="选择门店"
        :visible.sync="chooseStoreDialog"
        top="50px"
        width="500px"
        id="chooseStoreDialog"
    >
      <div style="display: flex;align-items: center;">
        <div style="border-left: 3px solid #1B84FF;width: 1px;height: 14px">

        </div>
        <div style="margin-right: 10px;font-weight: bold;font-size: 15px;margin-left: 4px;display: flex;align-items: center">
          <div style="line-height: 22px">
            请选择门店
          </div>
        </div>
        <el-select v-model="chooseStoreId" placeholder="请选择" size="mini" class="store_openingHours" @change="storeChange">
          <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="`${item.storeName}(${item.branchName})`"
              :value="item.id">
          </el-option>
        </el-select>
        <el-button type="primary" @click="submitChoose" style="margin-left: 10px">确定</el-button>
      </div>

    </el-dialog>


    <!-- 商品详情 -->
    <el-dialog v-if="goodsDetailVisible"  :visible.sync="goodsDetailVisible" appendToBody=true>
      <template #title>
        <div class="modal-content">
          <div class="modal-header">
            <h2 >商品详情</h2>
          </div>
        </div>
      </template>
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </el-dialog>

  </div>

</template>
<script>

import {mixins} from "@/views/Selection/index/mixins";
import storeCreate from "@/views/Store/create/index.vue";
import storeInfo from "@/views/Store/info/index.vue";
import EditTime from "@/views/Store/edit-time/index.vue";

export default {
  components: {EditTime, storeInfo, storeCreate},
  mixins: [mixins]
}
</script>
