<template>
  <div style="min-height: 80vh">
    <div class="card bg-light shadow-sm" style="background: #fff;margin-top: 10px;border-radius: 15px">
      <div class="card-body collapsible cursor-pointer rotate"
           style="background: #fff;border-radius: 15px;">
        <div>
          <div class="d-flex align-items-center">
            选择门店
            <el-select v-model="value" class="store_openingHours" placeholder="全部门店" size="mini"
                       style="margin-left: 10px"
                       @change="storeChange">
              <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.storeName"
                  :value="item.id">
              </el-option>
            </el-select>
            <div style="display: flex;align-items: center;margin-left: 16px">
              <div style="margin-right: 10px">
                收益时间:
              </div>
              <div>
                <el-date-picker
                    v-model="value2"
                    size="mini"
                    type="datetimerange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    align="right">
                </el-date-picker>
              </div>
            </div>
            <div class="d-flex align-items-center" style="margin-left: 10px">
              <el-button type="primary" size="small" round>查询</el-button>
              <el-button size="small" round>重置</el-button>
              <a class="btn btn-link" style="margin-left: 10px"
                 id="kt_horizontal_search_advanced_link"
                 data-bs-toggle="collapse"
                 href="#overview_search_form"
              >
                高级查询
              </a>
            </div>
          </div>
          <div id="overview_search_form" class="collapse">
            <div class="separator separator-dashed mt-9 mb-6"></div>
            <div class="row g-8 mb-8">

              <div class="col-lg-3">
                <label class="fs-6 form-label fw-bolder text-dark"
                >下单人手机号</label
                >
                <br/>
                <el-input
                    clearable
                    placeholder="请输入下单人手机号"
                >
                </el-input>
              </div>

              <div class="col-lg-3">
                <label class="fs-6 form-label fw-bolder text-dark"
                >商品来源</label
                >
                <br/>
                <el-select
                    class="w-100"
                    clearable
                    filterable
                    placeholder="请选择"
                >
                  <el-option
                      label="自有商品"
                      value="1"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="col-lg-3">
                <label class="fs-6 form-label fw-bolder text-dark"
                >收件人手机号</label
                >
                <br/>
                <el-input
                    clearable
                    placeholder="请输入收件人手机号"
                >
                </el-input>
              </div>
              <div class="col-lg-3">
                <label class="fs-6 form-label fw-bolder text-dark"
                >商品名称</label
                >
                <br/>
                <el-input
                    clearable
                    placeholder="请输入商品名称"
                >
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="border-radius: 15px;margin-top: 10px">
      <div class="tab-content">
        <div class="tab-pane fade show active">
          <div class="d-flex flex-column gap-7 gap-lg-10">
            <div class="card" style="border-radius:15px">
              <div class="card-header" style="display: flex;align-items: center">
                <div style="display:flex;align-items: center;padding: 20px 0;">
                  <div>
                    <div style="color: #C0C0BF;">商家应得(元)
                      <el-tooltip class="item" content="商家收益" effect="dark" placement="top-end"
                                  style="cursor: pointer">
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </div>
                    <div style="font-weight: bold;color: #FF7F5F;font-size: 24px;margin: 4px 0">
                      &yen;{{ headData.merchantsDeserveIt | formats }}
                    </div>
                    <div>
                      <el-link style="font-size: 11px" type="primary">查看全部明细 <i class="el-icon-arrow-right"></i>
                      </el-link>
                      <!--&nbsp;-->
                    </div>
                  </div>
                  <div style="margin-left: 20px;padding-right: 10px">
                    <div>
                      &nbsp;
                    </div>
                    <div style="font-weight: bold;font-size: 18px;">=</div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 10px">
                    <div style="color: #C0C0BF;">
                      销售额
                    </div>
                    <div style="font-weight: bold;font-size: 24px;margin: 4px 0">&yen;{{
                        headData.salesPrice | formats
                      }}
                    </div>
                    <div style="color: #C0C0BF;font-size: 11px">
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 20px;margin-right: 10px">
                    <div>
                      &nbsp;
                    </div>
                    <div style="width: 8px;height: 2px;background: #000">

                    </div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 14px">
                    <div style="color: #C0C0BF;">
                      促销费
                      <el-tooltip class="item" content="拼团、立减等活动优惠金额" effect="dark" placement="top-end"
                                  style="cursor: pointer">
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </div>
                    <div style="font-size: 24px;margin: 4px 0">&yen;{{ headData.reducePrice | formats }}</div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 20px;margin-right: 10px">
                    <div>
                      &nbsp;
                    </div>
                    <div style="width: 8px;height: 2px;background: #000">

                    </div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 14px">
                    <div style="color: #C0C0BF;">
                      服务费
                      <el-tooltip class="item" content="系统服务费" effect="dark"
                                  placement="top-end"
                                  style="cursor: pointer">
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </div>
                    <div style="font-size: 24px;margin: 4px 0">&yen;{{ headData.promotionFee | formats }}</div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 20px;margin-right: 10px">
                    <div>
                      &nbsp;
                    </div>
                    <div style="width: 8px;height: 2px;background: #000">
                      <div>
                        &nbsp;
                      </div>
                    </div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 14px">
                    <div style="color: #C0C0BF;">
                      商家承担退款
                      <el-tooltip class="item" content="退款订单中商家承担得金额" effect="dark" placement="top-end"
                                  style="cursor: pointer">
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </div>
                    <div style="font-size: 24px;margin: 4px 0">&yen;{{ headData.refundPrice | formats }}</div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 20px;margin-right: 10px">
                    <div>
                      &nbsp;
                    </div>
                    <div style="font-size: 20px">
                      +
                    </div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 14px">
                    <div style="color: #C0C0BF;">
                      锁粉所得
                      <el-tooltip class="item" content="锁定粉丝下单返佣" effect="dark" placement="top-end"
                                  style="cursor: pointer">
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </div>
                    <div style="font-size: 24px;margin: 4px 0">&yen;{{ headData.userPrice | formats }}</div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 20px;margin-right: 10px">
                    <div>
                      &nbsp;
                    </div>
                    <div style="font-size: 20px">
                      +
                    </div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 14px">
                    <div style="color: #C0C0BF;">
                      其他商家挂单服务费
                    </div>
                    <div style="font-size: 24px;margin: 4px 0">&yen;{{ headData.otherServiceChargePrice | formats }}</div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="card" style="margin-top: 10px">
          <div class="card-body">
            <div style="margin-top: 0">
              <div class="table-responsive" style="min-height: 40vh">
                <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
                >
                  <thead>
                  <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                    <th colspan="1" rowspan="1" style="min-width: 10rem;max-width: 20rem">
                      门店名称
                    </th>
                    <th colspan="1" rowspan="1">
                      销售额
                    </th>
                    <th colspan="1" rowspan="1">
                      促销费
                      <el-tooltip class="item" content="立减与拼团的合计费用" effect="dark" placement="top-end"
                                  style="cursor: pointer">

                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </th>
                    <th colspan="1" rowspan="1">
                      推广费
                      <el-tooltip class="item" content="系统服务费、用户返积分与挂单服务费的合计费用" effect="dark"
                                  placement="top-end" style="cursor: pointer">
                        <i class="el-icon-question" style="cursor: pointer"></i>
                      </el-tooltip>
                    </th>
                    <th colspan="1" rowspan="1">
                      商家承担退款
                    </th>
                    <th colspan="1" rowspan="1">
                      锁粉所得
                    </th>
                    <th colspan="1" rowspan="1">
                      其他商家挂单服务费
                    </th>
                    <th colspan="1" rowspan="1">
                      商家应得
                    </th>
                    <th class="text-center min-w-60px" colspan="1" rowspan="1" style="width: 166px;">
                      操作
                    </th>
                  </tr>
                  </thead>
                  <tbody class="fw-semibold text-gray-600">
                  <tr v-for="(item,index) in infoData" :key="index" :class="{
                         old:index%2!==0,
                         even:index%2===0
                      }">
                    <td class=" pe-0">
                      {{ item.storeName }}
                    </td>
                    <td class=" pe-0"><span class="fw-bold">{{ item.price.salesPrice | format }}</span></td>
                    <td class="pe-0"><span class="">{{ item.price.reducePrice * -1 | format }}</span></td>
                    <td class="pe-0"><span class="">{{ item.price.promotionFee * -1 | format }}</span></td>
                    <td class="pe-0"><span class="">{{ item.price.refundPrice * -1 | format }}</span></td>
                    <td class="pe-0"><span class="">{{ item.price.userPrice | format }}</span></td>
                    <td class="pe-0"><span class="">{{ item.price.otherServiceChargePrice | format }}</span></td>
                    <td class="pe-0"><span class="fw-bold">{{ item.price.merchantsDeserveIt | format }}</span></td>
                    <td class="text-center">
                      <el-link type="primary">明细</el-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
                <div style="display: flex;justify-content: right">
                  <paginations :limit.sync="queryParams.size" :page.sync="queryParams.current" :pagination="financialOverviewInfo"
                               :total="infoTotal"></paginations>
                </div>
            </div>
          </div>
        </div>


      </div>

    </div>
  </div>
</template>
<script>
import {mixins} from "@/views/Finance/components/overview/mixins";
import Paginations from "@/components/Paginations/index.vue";

export default {
  components: {Paginations},
  mixins: [mixins],
}
</script>
