<template>
  <div class="card card-flush h-md-50 mb-xl-10" style="display: flex;justify-content: space-between">
    <div class="card-header pt-3">
      <div class="card-title d-flex flex-column">
        <div class="d-flex align-items-center">
          <span class="fw-bold text-gray-900 me-2 lh-1 ls-n2">待处理</span>
        </div>
      </div>
    </div>
    <div class=" pt-0" style="margin:0 auto;width: 250px;margin-bottom: 60px">
      <div style="display: flex;justify-content: space-between;">
        <span>待开票 : <span style="font-size: 14px">{{ orderObj.afterSalesCount }} 个</span></span>
        <el-link :underline="false" style="margin-left: 20px" type="primary" @click="jumpPage(6)">详情>>></el-link>

      </div>
      <br>
      <div style="display: flex;justify-content: space-between;">
        <span style="font-size: 11px">待发货 :<span style="font-size: 14px"> {{
            orderObj.toBeShippedCount
          }} 个</span></span>
        <el-link :underline="false" style="margin-left: 20px" type="primary" @click="jumpPage(3)">详情>>>
        </el-link>
      </div>
    </div>

  </div>
</template>
<script>
import {getOrderListCountApi} from "@/api/orderApi";
import {mapState} from "vuex";
import {getVipCollectiveProcurementNotOverCountApi} from "@/api/VipCollectiveProcurementApi";

export default {
  name: 'tools',
  computed: {
    ...mapState("user", ["permissionList", "role"])
  },
  data() {
    return {
      orderObj: {
        pendingPaymentCount: 0,
        toBeShippedCount: 10,
        afterSalesCount: 0,
        pendingInvoiceCount: 0,
        vipCollectiveProcurementCount: 0
      }
    }
  },
  methods: {
    jumpPage(index) {

      if (this.permissionList.includes("finance:order:sj:query:list:flow")) {
        this.$router.push({name: "orderFlow", query: {index: index}});
        return
      }

      this.$router.push({name: "Order", query: {index: index}});
    },
    async getOrderListCount() {
      let {data} = await getOrderListCountApi();
      this.orderObj = data;
      this.orderObj.vipCollectiveProcurementCount = 0
      if (this.role.includes('vipMember')) {
        let {data} = await getVipCollectiveProcurementNotOverCountApi();
        this.orderObj.vipCollectiveProcurementCount = data
      }
    }
  },
  mounted() {
    // this.getOrderListCount();
  }
}
</script>
