// user.js
const state = {
    webUrl: "3jzc.com",
    obj: {
        webUrl: "",
        webIcp: "",
        email: "",
        phone: "",
        msg: "",
        webTitle: ""
    },
    webObj: [
        {
            webTitle: "三江智采",
            icon: "/favicon.ico",
            webUrl: "3jzc.com",
            logoUrl: "./logo-2-dark.png",
            headerLogoUrl: "./logo.png",
            webIcp: "浙ICP备20022022号-6",
            email: "server@enower.com",
            phone: "400-840-8088",
            msg: "2016-2024©三江智采",
            keFu: "./images/zhilidao_kefu.png",
            tel: "400-840-8088",
            // 短信模板ID
            tplId: "259853",
            accountName: "杭州星朗云商科技有限公司",
            openingBank: "中国银行杭州德迦支行",
            account: "392278064364"
        },
        {
            webTitle: "三江智采",
            icon: "/favicon.ico",
            webUrl: "https://yqw.3jzc.cn",
            headerLogoUrl: "./logo.png",
            logoUrl: "./logo-2-dark.png",
            webIcp: "浙ICP备20022022号-4",
            email: "server@enower.com",
            phone: "400-840-8088",
            msg: "2016-2024©三江智采",
            keFu: "./images/zhilidao_kefu.png",
            tel: "400-840-8088",
            tplId: "259853",
            accountName: "杭州星朗云商科技有限公司",
            openingBank: "中国银行杭州德迦支行",
            account: "392278064364"
        },
        {
            webTitle: "三江智采",
            icon: "/favicon.ico",
            webUrl: "https://saas.3jzc.cn",
            headerLogoUrl: "./logo.png",
            logoUrl: "./logo-2-dark.png",
            webIcp: "浙ICP备20022022号-4",
            email: "server@enower.com",
            phone: "400-840-8088",
            msg: "2016-2024©三江智采",
            keFu: "./images/zhilidao_kefu.png",
            tel: "400-840-8088",
            tplId: "259853",
            accountName: "杭州星朗云商科技有限公司",
            openingBank: "中国银行杭州德迦支行",
            account: "392278064364"
        },
        {
            webTitle: "浙礼道",
            icon: "/favicon.ico",
            webUrl: "https://saas.zhelidao.com",
            logoUrl: "./logo-2-dark-zhelidao.png",
            headerLogoUrl: "./logo-zhelidao.png",
            tel: "400-840-8088",
            webIcp: "浙ICP备20022022号-5",
            email: "server@enower.com",
            phone: "400-840-8088",
            msg: "2016-2024©浙礼道",
            keFu: "./images/zhilidao_kefu.png",
            tplId: "255551",
            accountName: "杭州浙礼道电子商务有限公司",
            openingBank: "中国银行杭州市高新技术开发区",
            account: "380583029691"
        },
        {
            webTitle: "杭州市礼品商会",
            icon: "/favicon.ico",
            webUrl: "https://www.hangzhougift.com",
            logoUrl: "./logo-2-dark-shanghui.png",
            headerLogoUrl: "./logo-shanghui.png",
            tel: "400-840-8088",
            webIcp: "浙ICP备2024094315号-2",
            email: "server@enower.com",
            phone: "400-840-8088",
            msg: "2016-2024©杭州市礼品商会",
            keFu: "./images/zhilidao_kefu.png",
            tplId: "255551",
            accountName: "杭州市礼品商会",
            openingBank: "中国光大银行股份有限公司杭州城西支行",
            account: "77420188000097632"
        }
        ,
        {
            webTitle: "联享惠企业端",
            icon: "/lxh-favicon.ico",
            webUrl: "https://c.lxhsaas.com",
            logoUrl: "./2.png",
            headerLogoUrl: "./lxh-logo-c.png",
            tel: "400-840-8088",
            webIcp: "浙ICP备2024094315号-2",
            email: "server@enower.com",
            phone: "400-840-8088",
            msg: "2016-2024©杭州市礼品商会",
            keFu: "./images/zhilidao_kefu.png",
            tplId: "255551",
            accountName: "杭州市礼品商会",
            openingBank: "中国光大银行股份有限公司杭州城西支行",
            account: "77420188000097632"
        }
    ]
};

const mutations = {
    SET_WEBICP(state, payload) {
        state.obj = payload;
        document.title = payload.webTitle;
    },
    SET_ICON(state, payload) {
        const link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = payload.icon;
        const head = document.head || document.getElementsByTagName('head')[0];
        const oldFavicons = document.querySelectorAll('link[rel="icon"]');
        oldFavicons.forEach(oldFavicon => head.removeChild(oldFavicon));
        head.appendChild(link);
    }
};

const actions = {
    /**
     * 设置webIcp
     */
    setWebIcp({commit}, amount) {
        commit('SET_WEBICP', amount)
        commit('SET_ICON', amount)
    },
    setIcon({commit}, amount) {
        commit('SET_ICON', amount)
    }
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
